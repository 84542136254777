import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.refDiv = React.createRef();
    this.state = {
      isSuperAdmin: false,
      isUserManagementAdmin: false,
      isTagManagementAdmin: false,
      isDispatchManagementAdmin: false,
      isLnfManagementAdmin: false,
      isReturnManagementAdmin: false,
      isMacAddressPairingManagementAdmin: false,
      isCouponManagementAdmin: false,
      isTagGenerationManagementAdmin: false,
      isBillingManagementAdmin: false,
      isVisualizationManagementAdmin: false,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const userInfo = JSON.parse(user);

      if (
        userInfo?.role === "superadmin" ||
        userInfo?.activitiesAccess?.includes("All")
      ) {
        this.setState(preState => ({
          ...preState,
          isSuperAdmin: true,
        }));
      } else if (userInfo?.role === "admin") {
        if (userInfo?.activitiesAccess?.includes("User Management")) {
          this.setState(preState => ({
            ...preState,
            isUserManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Tag Management")) {
          this.setState(preState => ({
            ...preState,
            isTagManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Dispatch Management")) {
          this.setState(preState => ({
            ...preState,
            isDispatchManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("LNF Management")) {
          this.setState(preState => ({
            ...preState,
            isLnfManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Return Management")) {
          this.setState(preState => ({
            ...preState,
            isReturnManagementAdmin: true,
          }));
        }
        if (
          userInfo?.activitiesAccess?.includes("MAC Address Pairing Management")
        ) {
          this.setState(preState => ({
            ...preState,
            isMacAddressPairingManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Coupon Management")) {
          this.setState(preState => ({
            ...preState,
            isCouponManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Tag Generation Management")) {
          this.setState(preState => ({
            ...preState,
            isTagGenerationManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Billing Management")) {
          this.setState(preState => ({
            ...preState,
            isBillingManagementAdmin: true,
          }));
        }
        if (userInfo?.activitiesAccess?.includes("Visualization Management")) {
          this.setState(preState => ({
            ...preState,
            isVisualizationManagementAdmin: true,
          }));
        }
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    // console.log("state: ", this.state);
    const {
      isSuperAdmin,
      isUserManagementAdmin,
      isTagManagementAdmin,
      isLnfManagementAdmin,
      isDispatchManagementAdmin,
      isReturnManagementAdmin,
      isMacAddressPairingManagementAdmin,
      isCouponManagementAdmin,
      isTagGenerationManagementAdmin,
      isBillingManagementAdmin,
      isVisualizationManagementAdmin,
    } = this.state;
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <Link to="/#">
                  <i className="bx bx-home-circle" />
                  <span>{this.props.t("Dashboards")}</span>
                </Link>
              </li>
              {(isSuperAdmin || isUserManagementAdmin) && (
                <li>
                  <Link to="/user-list">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Users")}</span>
                  </Link>
                </li>
              )}
              {(isSuperAdmin || isTagManagementAdmin) && (
                <li>
                  <Link to="/tags-list">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Tags")}</span>
                  </Link>
                </li>
              )}
                {(isSuperAdmin || isDispatchManagementAdmin) && (
                <li>
                  <Link to="/dispatch">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Dispatch")}</span>
                  </Link>
                </li>
              )}

              {(isSuperAdmin || isLnfManagementAdmin) && (
                <li>
                  <Link to="/lostandfound">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Tata Aig Tags")}</span>
                  </Link>
                </li>
              )}
              {(isSuperAdmin || isReturnManagementAdmin) && (
                <li>
                  <Link to="/returnproducts">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Return Transcations")}</span>
                  </Link>
                </li>
              )}
              {(isSuperAdmin || isMacAddressPairingManagementAdmin) && (
                <li>
                  <Link to="/macpairing">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Data Pairing")}</span>
                  </Link>
                </li>
              )}
              {/* {(isSuperAdmin || isCouponManagementAdmin) && (
                <li>
                  <Link to="/coupon-list">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Coupons")}</span>
                  </Link>
                </li>
              )} */}
              {(isSuperAdmin || isTagGenerationManagementAdmin) && (
                <li>
                  <Link to="/taggeneration-list">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("TagGeneration")}</span>
                  </Link>
                </li>
              )}
              {(isSuperAdmin) && (
                <li>
                  <Link to="/admin-list">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Role Management")}</span>
                  </Link>
                </li>
              )}

              {(isSuperAdmin || isVisualizationManagementAdmin) && (
                <li>
                  <Link to="/visualization">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Visualization")}</span>
                  </Link>
                </li>
              )}

              {(isSuperAdmin || isBillingManagementAdmin) && (
                <li>
                  <Link to="/billing-management">
                    <i className="bx bxs-user-detail" />
                    <span>{this.props.t("Billing Management")}</span>
                  </Link>
                </li>
              )}

              {/* <li className="menu-title">{this.props.t("Apps")}</li> */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
